import $ from 'jquery'
import slick from 'slick-carousel'
import LazyLoad from "vanilla-lazyload"
import ModalVideo from 'modal-video';

/* SETUP SLICK
  ----------------------------- */
export const setupSlideshow = (el, type = 'basic', slidesNumber = 1) => {
    const $el = $(el);
    const opts = {
        basic: {
            autoplay: true,
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 2000,
        },
        images: {
            autoplay: true,
            dots: false,
            arrows: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            autoplaySpeed: 2000,
        },
        logos: {
            autoplay: true,
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplaySpeed: 2000,
        },
        popup: {
            autoplay: false,
            arrows: false,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,

        }
    }

    $el.slick(opts[type])
}

window.onload = () => {
    if ($('.slider-init').length > 0) {
        $('.slider-init').each(function () {
            setupSlideshow($(this), $(this).data('slick'), $(this).data('slides'))
        })
    }

    const bodyClasses = $('body').attr("class").split(/\s+/)
    $.each(bodyClasses, function (key, value) {
        switch (value) {
            case "home":
                break
            default:
                console.log("page loaded");
        }
    })
}

/* HEADER ANIMATION
  ----------------------------- */
export const menuOverlay = () => {
    const toggle = $('.header__toggle')
    const bar = $('.header__toggle .bar')
    const overlay = $('.header--offcanvas, .header__inner, .content-area, body')
    const menuWithChildren = $('.menu-item-has-children')

    // Open Offcanvas
    toggle.on('click', function () {
        overlay.toggleClass('open')
        bar.toggleClass('animate')
        toggle.toggleClass('open')
    })

    // Open Submenu
    menuWithChildren.each(function () {
        $(this).find('.sub-menu').css('width', $(this).width())
        $(this).find('> a').on('click', function (e) { e.preventDefault() })

        $(this).on('click', function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active').find('.sub-menu').slideToggle()
            } else {
                menuWithChildren.removeClass('active')
                $('.sub-menu').css('display', 'none')
                $(this).addClass('active').find('.sub-menu').slideToggle()
            }
        })
    })
}

/* LAZY LOAD
  ----------------------------- */
export const lazyLoad = () => {
    const lazyLoadInstance = new LazyLoad({})
}



/* POPUP GALLERY
  ----------------------------- */
// popupGallery.js

// popupGallery.js


export const popupGallery = () => {
    const hideAllPopups = () => {
        $('.section__popup').removeClass('open');
    };

    $('.section__wrapper img').on('click', function () {
        const gallery = $(this).closest('.section--gallery');
        const popup = gallery.find('.section__popup');

        hideAllPopups(); // Hide all popups

        popup.addClass('open');

        const slick = popup.find('.slider-init').slick('getSlick');
        slick.slickGoTo($(this).index());
    });

    $('.section--gallery .close').on('click', function () {
        const popup = $(this).closest('.section__popup');
        popup.removeClass('open');
    });

    $(document).mouseup(function (e) {
        const popups = $('.section__popup');
        popups.each(function () {
            const popup = $(this);
            if (!popup.is(e.target) && popup.has(e.target).length === 0) {
                popup.removeClass('open');
            }
        });
    });

    $(window).on('resize', function () {
        const popups = $('.section__popup.open');
        popups.each(function () {
            // Add your resize logic for each visible popup
        });
    });
};


/* SETUP MODALVIDEO
  ----------------------------- */
export const setupModalVideo = () => {
    if ($('.js-modal-btn').length > 0) {
        new ModalVideo('.js-modal-btn', {
            nocookie: true,
            autoplay: 1
        })
    }
}

import $ from 'jquery'
import * as project from './functions'

window.onload = () => {
    project.lazyLoad()
    project.setupModalVideo()
    
    $('.section__video-wrapper').on({
        mouseenter: function () {
            if($(window).width() > 1024) {
                $('.section__video-wrapper').addClass('active')
                $('.overlay-links').addClass('show')
            }
        },
        mouseleave: function () {
            if ($(window).width() > 1024) {
                $('.section__video-wrapper').removeClass('active')
                $('.overlay-links').removeClass('show')
            }
        }
    });

    $('.section__video-wrapper').on('click', function(){
        $('.section__video-wrapper').addClass('active')
        $('.overlay-links').addClass('show')
    })

    $('.toggle-close').on('click', function(){
        $('.section__video-wrapper').removeClass('active')
        $('.overlay-links').removeClass('show')
        return false;
    })
}
